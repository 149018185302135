<template>
	<header id="site-header" class="top-0 z-20 w-full text-black" :class="isTop ? 'absolute Xbg-white':'fixed shadow-md'">
		<!-- <div class="py-[10px] text-white bg-black" v-show="isTop">
			<div class="container">
				<div class="flex flex-wrap items-center justify-between space-y-2 text-sm text-center lg:text-left lg:text-base lg:space-y-0">
					<div class="items-center w-full space-x-2 lg:flex lg:w-auto">
						<svg class="inline w-5 h-5 lg:w-6 lg:h-6" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"></path><path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"></path></svg>
						<div class="inline font-light">
							924 Busilak St., Brgy. Barangka Drive, Mandaluyong City
						</div>
					</div>
					<div class="items-center hidden w-full space-x-2 lg:flex lg:w-auto">
						<svg class="inline w-5 h-5 lg:w-6 lg:h-6" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"></path></svg>
						<div class="inline font-light">
							<a class="hover:underline" href="mailto:inquiries@mtsc.com.ph">inquiries@mtsc.com.ph</a>
						</div>
					</div>
				</div>
			</div>
		</div> -->
		<div class="" :class="isTop ? 'py-4 X__text-white X__lg:text-[color:inherit]':'py-2 bg-white bg-color-200ms'">
			<div class="container" :class="{'px-0 lg:pl-4': !isTop}">
				<div class="flex flex-wrap items-center justify-center gap-y-4 lg:gap-y-8 X__lg:-mr-4" :class="isTop ? 'h-[160px] lg:h-[160px] sm:justify-between sm:flex-nowrap':'h-[28px] lg:h-[57px] lg:duration-200 lg:justify-between lg:flex-nowrap'">

					<router-link to="/" class="justify-center lg:justify-start items-center w-full gap-x-[1.75rem] sm:w-auto" :class="isTop ? 'flex':'hidden lg:flex'">
						<img class="w-auto duration-200 lg:mx-auto" :class="isTop ? 'h-[98px] lg:h-[auto]':'h-[57px]'" src="@/assets/img/logo.png" alt="MTSC Transport Services Logo" width="160" height="160">
					</router-link>

					<div class="flex flex-wrap justify-center border-black lg:flex-nowrap" :class="isTop ? 'bg-white rounded-full py-2' : 'divide-x'">
						<div class="flex items-center gap-x-2 sm:px-4" :class="isTop ? 'sm:gap-x-4 px-3 lg:pl-3 pr-6 lg:pr-8' : 'X__text-white'">
							<img class="w-auto" :class="isTop ? 'h-8 lg:h-[50px]' : 'h-4 lg:h-[36px] X__invert'" src="@/assets/img/icon-phone.png" alt="Phone" width="73" height="74">
							<div class="flex items-center">
								<div>
									<div class="hidden font-bold sm:block" v-show="isTop">Call Now to Inquire:</div>
									<div class="" :class="isTop ? '' : 'flex divide-x'">
										<!-- <a class="block text-lg lg:text-3xl hover:underline border-gray-400" :class="isTop ? '' : 'px-2'" href="tel:+639171230651"><span>0917 123 0651</span></a> -->
										<a class="block text-lg lg:text-3xl hover:underline border-gray-400" :class="isTop ? '' : 'px-2'" href="tel:+639209080357"><span>0920 908 0357</span></a>
									</div>
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>
	</header>
</template>

<script>
export default {
	data () {
		return {
			isTop: true,
		};
	},
	mounted () {
		document.addEventListener('scroll', this.handleScroll, {passive: true});
	},
	methods: {
		handleScroll() {
			this.isTop = window.scrollY < 250;
			// if (window.pageYOffset >= 200) {
			// 	if (this.isTop) this.isTop = false;
			// } else if ( document.documentElement.scrollTop < 200 ) {
			// 	if (!this.isTop) this.isTop = true;
			// }
		},
	},
}
</script>

<style scoped>
.bg-color-200ms {
	transition: background-color 200ms;
}
</style>
